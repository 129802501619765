import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    form_visibility: false,
    selected_form: null,
    selected_student: null
  },
  getters: {
    form_visibility: state => state.form_visibility,
    selected_student: state => state.selected_student,
    selected_form: state => state.selected_form
  },
  mutations: {
    RESET(state) {
      state.selected_form = null
      state.form_visibility = false
      state.selected_student = null
    },
    UPDATE_STUDENT(state, item) {
      item.schedule = _.clone(state.selected_student.schedule)
      item.address = _.clone(state.selected_student.address)
      item.parents = _.clone(state.selected_student.parents)

      state.selected_student = item
    },
    UPDATE_ADDRESS(state, item) {
      Vue.set(state.selected_student, 'address', item)
    },
    UPDATE_PARENT(state, item) {
      let parentIndex = _.findIndex(state.selected_student.parents, { id: item.id })
      Vue.set(state.selected_student.parents, parentIndex, item)
    },
    SET_SELECTED_STUDENT(state, item) {
      state.selected_student = item
    },
    UNSET_SELECTED_STUDENT(state) {
      state.selected_student = null
    },
    SHOW_FORM(state, form) {
      state.form_visibility = true
      state.selected_form = form
    },
    HIDE_FORM(state) {
      state.form_visibility = false
      state.selected_student = null
      state.selected_form = null
    }
  },
  actions: {
    /** Update single student */
    async updateStudent({ commit }, { item, next = null }) {
      try {
        let response = await axios.put(`api/student/information/${item.id}`, item)
        commit('UPDATE_STUDENT', response.data.student)
        Vue.toasted.success('Data inti pribadi telah tersimpan')
        if (next !== null) {
          commit('SHOW_FORM', next)
        }
      } catch (error) {
        if (error.response.status === 422) {
          error.response.data.message.forEach(message => {
            Vue.toasted.error(message)
          });
        } else {
          Vue.toasted.error('Pengeditan data inti diri gagal. Mohon periksa kembali kelengkapan pengisian data')
        }
        return error
      }
    },

    /** Update student address */
    async updateAddress({ commit }, { item, next = null }) {
      try {
        let response = await axios.put(`api/student/address/${item.id}`, item)
        commit('UPDATE_ADDRESS', response.data.student_address)
        Vue.toasted.success('Data alamat telah tersimpan')
        if (next !== null) {
          commit('SHOW_FORM', next)
        }
      } catch (error) {
        error
        Vue.toasted.error('Pengeditan alamat gagal. Mohon periksa kembali kelengkapan pengisian data')
      }
    },

    /** Update student parent */
    async updateParent({ getters, commit }, { item, next = null }) {
      let maps = { father: "ayah", mother: "ibu", guardian: "wali" };
      let formType = maps[getters.selected_form]

      try {
        let response = await axios.put(`api/student/parent/${item.id}`, item)
        commit('UPDATE_PARENT', response.data.student_parent)
        Vue.toasted.success(`Pengeditan data ${formType} berhasil`)
        if (next !== null) {
          commit('SHOW_FORM', next)
        }
      } catch (error) {
        // bypass guardian form when data is not present
        if (getters.selected_form === 'guardian') {
          Vue.toasted.info('Data wali belum lengkap. Abaikan jika tidak terdapat wali')
          if (next !== null) {
            commit('SHOW_FORM', next)
          }
        }
        else {
          error
          Vue.toasted.error(`Pengeditan data ${formType} gagal. Mohon periksa kembali kelengkapan pengisian data`)

        }
      }
    },
  }
}